<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <router-link
              v-if="$currentUserCan($permissions.PERM_VIEW_APP_ADMIN)"
              :to="{ name: 'Reseller Page' }"
              v-slot="{ href, navigate }"
              custom
            >
              <a :href="href" @click="navigate">
                <i class="far fa-long-arrow-left"></i>
              </a>
            </router-link>
            <h3>{{ $t("RESELLER_PRODUCTS.RESELLER_PRODUCTS_LIST") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.RESELLER_MODULE") }}</span>
              </li>
              <li>
                <span>{{
                  $t("RESELLER_PRODUCTS.RESELLER_PRODUCTS_LIST")
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openResellerProductCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_RESELLER_PRODUCTS)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{
              $t("RESELLER_PRODUCTS.ADD_RESELLER_PRODUCT")
            }}</span>
          </base-button>
        </div>
      </div>

      <reseller-product-list-table
        @onViewResellerProduct="openResellerProductViewModal"
        @onEditResellerProduct="openResellerProductEditModal"
        @onDeleteResellerProduct="deleteResellerProduct"
        @onAddResellerProduct="openResellerProductCreateModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewResellerProductModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewResellerProductModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RESELLER_PRODUCT_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeResellerProductModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("RESELLER_PRODUCTS.VIEW_RESELLER_PRODUCT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-reseller-product-page
              v-if="openResellerProduct"
              :resellerProductId="openResellerProduct.id"
              @onEditResellerProduct="openResellerProductEditModal"
              @onDeleteResellerProduct="deleteResellerProduct"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditResellerProductModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditResellerProductModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RESELLER_PRODUCT'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeResellerProductModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("RESELLER_PRODUCTS.EDIT_RESELLER_PRODUCT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <!--<base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openResellerProductViewModal(openResellerProduct)"
                >
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeResellerProductModal">
                <i class="fal fa-times"></i>
              </button>-->
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-reseller-product-page
              v-if="openResellerProduct"
              :resellerProductId="openResellerProduct.id"
              @onViewResellerProduct="openResellerProductViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddResellerProductModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddResellerProductModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RESELLER_PRODUCT'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeResellerProductModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("RESELLER_PRODUCTS.ADD_RESELLER_PRODUCT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-reseller-product-page
              @onViewResellerProduct="openResellerProductViewModal"
              @onCloseResellerProductModal="closeResellerProductModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button, Message } from "element-ui";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import ResellerProductListTable from "./partials/ResellerProductListTable.vue";
import EditResellerProductPage from "./components/EditResellerProductComponent.vue";
import AddResellerProductPage from "./components/AddResellerProductComponent.vue";
import ViewResellerProductPage from "./components/ViewResellerProductComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";

export default {
  layout: "DashboardLayout",

  components: {
    WrapperExpandButton,
    ResellerProductListTable,
    EditResellerProductPage,
    AddResellerProductPage,
    ViewResellerProductPage,
    [Button.name]: Button,
    [Message.name]: Message,
  },

  mixins: [],

  computed: {},

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewResellerProductModalOpened = false;
    let isEditResellerProductModalOpened = false;
    let isAddResellerProductModalOpened = false;
    let openResellerProduct = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewResellerProductModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditResellerProductModalOpened = true;
      }
      openResellerProduct = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddResellerProductModalOpened = true;
    }
    return {
      isViewResellerProductModalOpened: isViewResellerProductModalOpened,
      isEditResellerProductModalOpened: isEditResellerProductModalOpened,
      isAddResellerProductModalOpened: isAddResellerProductModalOpened,
      openResellerProduct: openResellerProduct,
      renderKey: 1,
    };
  },

  methods: {
    openResellerProductCreateModal() {
      this.closeResellerProductModal();
      this.isAddResellerProductModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerProducts",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openResellerProductViewModal(resellerproduct, reRender = false) {
      this.closeResellerProductModal();
      this.openResellerProduct = resellerproduct;
      this.isViewResellerProductModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerProducts",
          query: {
            id: this.openResellerProduct.id,
            action: QUERY_ACTIONS_VIEW,
          },
        }).href
      );
    },
    openResellerProductEditModal(resellerproduct) {
      this.closeResellerProductModal();
      this.openResellerProduct = resellerproduct;
      this.isEditResellerProductModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerProducts",
          query: {
            id: this.openResellerProduct.id,
            action: QUERY_ACTIONS_EDIT,
          },
        }).href
      );
    },
    closeResellerProductModal() {
      this.isAddResellerProductModalOpened = false;
      this.isViewResellerProductModalOpened = false;
      this.isEditResellerProductModalOpened = false;
      this.openResellerProduct = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerProducts",
          query: {},
        }).href
      );
    },

    async deleteResellerProduct(resellerProduct) {
      const confirmation = await swal.fire({
        text: this.$t("RESELLER_PRODUCTS.DELETE_THIS_RESELLER_PRODUCT"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "resellerProducts/destroy",
            resellerProduct.id
          );
          this.renderKey++;
          this.closeResellerProductModal();
          this.$notify({
            type: "success",
            message: this.$t("RESELLER_PRODUCTS.RESELLER_PRODUCT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
