<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-image">
        <img
          v-if="resellerProduct.picture"
          :src="`${resellerProduct.picture}`"
          class="argon-image"
        />
        <img v-else src="/img/kw-default-image.svg" class="argon-image" />
      </div>
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ resellerProduct.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="resellerProduct.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.PRICE") }}</dt>
          <dd>
            {{ $formatCurrency(resellerProduct.price) }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.TAXES") }}</dt>
          <dd>
            <dl
              class="row"
              v-for="(tax, key) in resellerProduct.taxes"
              :key="key"
            >
              <dt>{{ tax.name }}</dt>
              <dd v-if="tax.type === TAX_TYPE_PERCENTAGE">{{ tax.value }} %</dd>
              <dd v-if="tax.type === TAX_TYPE_AMOUNT">
                {{ $formatCurrency(tax.value) }}
              </dd>
            </dl>
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
        >
          <dt>{{ $t("COMMON.RESELLER") }}</dt>
          <dd>
            <reseller :reseller="resellerProduct.reseller" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="resellerProduct.created_at">
            {{ $formatDate(resellerProduct.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="resellerProduct.updated_at">
            {{ $formatDate(resellerProduct.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";

export default {
  name: "reseller-product-view-global",

  components: {},

  props: ["resellerProduct"],

  data() {
    return {
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    resellerProduct(resellerProduct) {},
  },
};
</script>
