<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">
      {{ $t("COMMON.GALLERY") }}
    </h3>
    <div class="row" style="">
      <div
        class="col-4"
        v-for="(image, key) in resellerProduct.gallery"
        :key="key"
      >
        <img :src="image" class="argon-image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reseller-product-view-gallery",

  components: {},

  props: ["resellerProduct"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    resellerProduct(resellerProduct) {},
  },
};
</script>
